@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #ffffff;
}

@layer base {
    html {
        @apply scroll-smooth
    }
    body {
        /*@apply bg-[#edebe2]*/
        @apply bg-[--primary-color] dark:bg-gray-900
    }

    .serviceBox {
        transition: 0.7s ;
    }
/* Cernobile karty
    .flip-card {
        perspective: 1000px;
        width: 100%;
        max-width: 300px; // Fixní šířka
        margin: auto;
        cursor: pointer;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 400px; // Fixní výška
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-radius: 1rem;
        transition: background-color 0.3s ease;
    }

    .flip-card-front {
        background-size: cover;
        background-position: center;
        filter: grayscale(100%); // Černobílý obrázek
    }

    .flip-card-front:hover {
        filter: grayscale(0%); // Barevný obrázek při najetí myší
    }

    .flip-card-front h3 {
        font-weight: bold;
        font-size: 25px;
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); // Stín textu pro lepší čitelnost
        background: rgba(0, 0, 0, 0.3); // Poloprůhledné pozadí
        padding: 5px;
        border-radius: 0.5rem;
    }

    .flip-card-back {
        background-color: #B3E5FC; // Světle modrá
        transform: rotateY(180deg);
    }

    .flipped {
        transform: rotateY(180deg);
    }

    .click-hint {
        margin-top: 20px;
        font-size: 14px;
        color: #666;
    }

    .flip-card-front .icon {
        font-size: 3rem;
        color: #007BFF; // Primární modrá barva
    }*/

    .flip-card {
        perspective: 1000px;
        width: 100%;
        max-width: 300px; /* Fixní šířka */
        margin: auto;
        cursor: pointer;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 400px; /* Fixní výška */
        transition: transform 0.8s;
        transform-style: preserve-3d;
    }

    .flip-card-front,
    .flip-card-back,
    .flip-card-back-ninja,
    .flip-card-back-care {
        position: absolute;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        border-radius: 1rem;
        transition: transform 0.3s ease; /* Přechod pro zoom efekt */
    }

    .flip-card-front {
        background-size: cover;
        background-position: center;
    }

    .flip-card-front:hover {
        transform: scale(1.05);
    }

    .flip-card-front h3 {
        font-weight: bold;
        font-size: 25px;
        color: white;
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Stín textu pro lepší čitelnost */
        background: rgba(0, 0, 0, 0.5); /* Poloprůhledné pozadí */
        padding: 7px;
        border-radius: 0.5rem;
    }

    .flip-card-back {
        background-color: #E0F2FE; /* Světle modrá */
        transform: rotateY(180deg);
    }

    .flip-card-back-ninja {
        background-color: #fbf0b5;
        transform: rotateY(180deg);
    }

    .flip-card-back-care {
        background-color: #fadce8;
        transform: rotateY(180deg);
    }

    .flipped {
        transform: rotateY(180deg);
    }

    .click-hint {
        margin-top: 20px;
        font-size: 14px;
        color: #666;
    }

    .flip-card-front .icon {
        font-size: 3rem;
        color: #007BFF; /* Primární modrá barva */
    }

    .text-wrapper {
        position: relative;
    }

    .text-wrapper-left img {
        float: left; /* Umožní textu obklopit obrázek */
        margin-left: 1rem; /* Mezery mezi textem a obrázkem */
        margin-bottom: 1rem; /* Mezery mezi textem a obrázkem */
        width: 12rem; /* Šířka obrázku */
        height: 12rem; /* Výška obrázku */
    }

    .text-wrapper-right img {
        float: right; /* Umožní textu obklopit obrázek */
        margin-left: 1rem; /* Mezery mezi textem a obrázkem */
        margin-bottom: 1rem; /* Mezery mezi textem a obrázkem */
        width: 12rem; /* Šířka obrázku */
        height: 12rem; /* Výška obrázku */
    }

    @layer utilities{
        .animation-delay-2000 {
            animation-delay: 2s;
        }
        .animation-delay-4000 {
            animation-delay: 4s;
        }
    }




}